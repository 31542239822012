import {
    BalCard,
    BalCardContent,
    BalHeading,
    BalText,
} from "@baloise/ds-react";
import { graphql, navigate } from "gatsby";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { NeedHelpBlock, QuoteLayout } from "@modules/quote";
import { Footer, InfoBlock, Navigation } from "@modules/shared/components";
import { ModalName } from "@modules/shared/enums";
import { getNavigationPages } from "@modules/shared/helpers";
import { AppState, appState, modalState } from "@modules/shared/state";
import { PageProps } from "@modules/shared/types";

const Intro: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, language, ownPageObjects: allPages } = pageContext;
    const appData = useRecoilValue<AppState>(appState);
    const setModal = useSetRecoilState(modalState);
    const { t } = useTranslation();
    const { nextPage } = getNavigationPages(allPages, page);

    useEffect(() => {
        if (!appData?.InsuranceChoice?.insurance?.type) {
            // Navigate to the product page when there is no insurance choice selected
            // A quote flow can only started when there is a simulation available
            navigate("/");
        }
    }, [appData]);

    return (
        <QuoteLayout
            title={t("quote.intro.title")}
            page={page}
            allPages={allPages}
            language={language}
            hideHelp={false}
        >
            <div className="container my-large">
                <div className="grid is-gapless">
                    <div className="col is-12 desktop:is-8">
                        <div className="flex flex-direction-column">
                            <div className="flex-1">
                                <BalHeading level="h1">
                                    {t("quote.intro.title")}
                                </BalHeading>
                                <BalText className="mb-x-large">
                                    {t("quote.intro.description")}
                                </BalText>

                                <BalHeading level="h3" className="mb-medium">
                                    {t("quote.intro.infoNeeded.title")}
                                </BalHeading>

                                <div className="grid mb-large">
                                    <div className="col">
                                        <InfoBlock
                                            SVGElement={
                                                <svg
                                                    width="36"
                                                    height="32"
                                                    viewBox="0 0 36 32"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g id="Id-card">
                                                        <path
                                                            id="vector"
                                                            d="M33 10.4444H3V26.4444C3 27.0069 3.4375 27.4444 4 27.4444H6C6 24.6944 8.1875 22.4444 11 22.4444H15C17.75 22.4444 20 24.6944 20 27.4444H32C32.5 27.4444 33 27.0069 33 26.4444V10.4444ZM4 2.44444H32C34.1875 2.44444 36 4.25694 36 6.44444V26.4444C36 28.6944 34.1875 30.4444 32 30.4444H4C1.75 30.4444 0 28.6944 0 26.4444V6.44444C0 4.25694 1.75 2.44444 4 2.44444ZM17 16.4444C17 17.8819 16.1875 19.1944 15 19.9444C13.75 20.6319 12.1875 20.6319 11 19.9444C9.75 19.1944 9 17.8819 9 16.4444C9 15.0694 9.75 13.7569 11 13.0069C12.1875 12.3194 13.75 12.3194 15 13.0069C16.1875 13.7569 17 15.0694 17 16.4444ZM23.5 13.4444H28.5C29.3125 13.4444 30 14.1319 30 14.9444C30 15.8194 29.3125 16.4444 28.5 16.4444H23.5C22.625 16.4444 22 15.8194 22 14.9444C22 14.1319 22.625 13.4444 23.5 13.4444ZM23.5 19.4444H28.5C29.3125 19.4444 30 20.1319 30 20.9444C30 21.8194 29.3125 22.4444 28.5 22.4444H23.5C22.625 22.4444 22 21.8194 22 20.9444C22 20.1319 22.625 19.4444 23.5 19.4444Z"
                                                            fill="#2861A4"
                                                        />
                                                    </g>
                                                </svg>
                                            }
                                            title="quote.intro.infoNeeded.one.title"
                                        />
                                    </div>
                                    <div className="col">
                                        <InfoBlock
                                            SVGElement={
                                                <svg
                                                    width="40"
                                                    height="32"
                                                    viewBox="0 0 40 32"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g id="car-side">
                                                        <path
                                                            id="vector"
                                                            d="M10.6875 5.44444C9.6875 5.44444 8.75 6.06944 8.375 7.06944L6.1875 12.4444H14V11.4444V6.44444V5.44444H10.6875ZM17 12.4444H28.875L24 6.38194C23.5 5.81944 22.8125 5.44444 22.0625 5.44444H17V6.44444V11.4444V12.4444ZM32.75 12.5069C36.8125 12.8819 40 16.3194 40 20.4444V23.4444C40 24.5694 39.0625 25.4444 38 25.4444H35.875C35.4375 28.3194 32.9375 30.4444 30 30.4444C27 30.4444 24.5 28.3194 24.0625 25.4444H15.875C15.4375 28.3194 12.9375 30.4444 10 30.4444C7 30.4444 4.5 28.3194 4.0625 25.4444H2C0.875 25.4444 0 24.5694 0 23.4444V16.4444C0 14.6319 1.1875 13.0694 2.875 12.6319L5.5625 5.94444C6.4375 3.81944 8.4375 2.44444 10.6875 2.44444H22.0625C23.75 2.44444 25.3125 3.25694 26.375 4.50694L32.75 12.5069ZM27.125 23.4444C26.75 24.5069 26.9375 25.6319 27.6875 26.4444C28.375 27.2569 29.4375 27.6944 30.5 27.4444C31.5625 27.2569 32.4375 26.5069 32.8125 25.4444C33.1875 24.4444 32.9375 23.3194 32.25 22.5069C31.5625 21.6944 30.5 21.2569 29.4375 21.5069C28.375 21.6944 27.5 22.4444 27.125 23.4444ZM35.625 22.4444H37V20.4444C37 17.6944 34.75 15.4444 32 15.4444H4C3.4375 15.4444 3 15.9444 3 16.4444V22.4444H4.3125C5.125 20.1319 7.375 18.4444 10 18.4444C12.5625 18.4444 14.8125 20.1319 15.625 22.4444H24.3125C25.125 20.1319 27.375 18.4444 30 18.4444C32.5625 18.4444 34.8125 20.1319 35.625 22.4444ZM10 21.4444C8.875 21.4444 7.9375 22.0694 7.375 22.9444C6.8125 23.8819 6.8125 25.0694 7.375 25.9444C7.9375 26.8819 8.875 27.4444 10 27.4444C11.0625 27.4444 12 26.8819 12.5625 25.9444C13.125 25.0694 13.125 23.8819 12.5625 22.9444C12 22.0694 11.0625 21.4444 10 21.4444Z"
                                                            fill="#2861A4"
                                                        />
                                                    </g>
                                                </svg>
                                            }
                                            title="quote.intro.infoNeeded.two.title"
                                            description="quote.intro.infoNeeded.two.description"
                                        />
                                    </div>
                                    <div className="col">
                                        <InfoBlock
                                            SVGElement={
                                                <svg
                                                    width="32"
                                                    height="33"
                                                    viewBox="0 0 32 33"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g id="clock-rotate-left">
                                                        <path
                                                            id="vector"
                                                            d="M3 7.13194C5.875 3.13194 10.625 0.444443 16 0.444443C24.8125 0.444443 32 7.63194 32 16.4444C32 25.3194 24.8125 32.4444 16 32.4444C12.6875 32.4444 9.6875 31.5069 7.125 29.8194C6.4375 29.3819 6.25 28.4444 6.75 27.7569C7.1875 27.0694 8.125 26.8819 8.8125 27.3194C10.875 28.6944 13.3125 29.4444 16 29.4444C23.125 29.4444 29 23.6319 29 16.4444C29 9.31944 23.125 3.44444 16 3.44444C11.375 3.44444 7.3125 5.88194 5 9.44444H8.5C9.3125 9.44444 10 10.1319 10 10.9444C10 11.8194 9.3125 12.4444 8.5 12.4444H1.5C0.625 12.4444 0 11.8194 0 10.9444V3.94444C0 3.13194 0.625 2.44444 1.5 2.44444C2.3125 2.44444 3 3.13194 3 3.94444V7.13194ZM16 8.44444H15.9375C16.8125 8.44444 17.4375 9.13194 17.4375 9.94444V15.8819L21.5 19.9444C22.125 20.5069 22.125 21.4444 21.5 22.0069C20.9375 22.6319 20 22.6319 19.4375 22.0069L14.9375 17.5069C14.625 17.2569 14.5 16.8819 14.5 16.4444V9.94444C14.5 9.13194 15.125 8.44444 16 8.44444Z"
                                                            fill="#2861A4"
                                                        />
                                                    </g>
                                                </svg>
                                            }
                                            title="quote.intro.infoNeeded.three.title"
                                            description="quote.intro.infoNeeded.three.description"
                                        />
                                    </div>
                                </div>

                                <BalCard className="mb-x-large">
                                    <BalCardContent>
                                        <BalHeading level="h4">
                                            {t(
                                                "quote.intro.whatAfterQuote.title",
                                            )}
                                        </BalHeading>
                                        <BalText
                                            color="grey"
                                            className="mb-none"
                                        >
                                            {t(
                                                "quote.intro.whatAfterQuote.description",
                                            )}
                                        </BalText>
                                    </BalCardContent>
                                </BalCard>

                                <Navigation
                                    language={language}
                                    t={t}
                                    nextPageBtnText="quote.intro.requestQuoteButtonText"
                                    onNextClick={() => {
                                        const nextPagePath =
                                            nextPage?.paths[language];

                                        if (nextPagePath) {
                                            navigate(nextPagePath);
                                        }
                                    }}
                                />
                            </div>
                            <Footer />
                        </div>
                    </div>
                    <div className="col desktop:is-4 touch:hidden h-full">
                        <div className="pl-large">
                            <NeedHelpBlock
                                onHelpClick={() => setModal(ModalName.Contact)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </QuoteLayout>
    );
};

export default Intro;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
